#loginmain {
  height: 100vh;
  width: 100%;
  background-color: #f2f6f7;
  font-family: inherit !important;
  font-size: medium;
}

#logincontent {
  margin: auto;
  width: 450px;
  padding: 5px;
  height: 100%;
}

#loginheader {
  background-color: #f2f6f7;
  height: 5vh !important;
}

#footer {
  left: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 55px;
  color: #e9efee;
  text-align: center;
  background-color: #333333;
  max-height: 55px;
}

#footer p {
  left: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
}

#logo {
  margin: auto;
  display: block;
  width: 365px;
}

img {
  height: 73px;
  border-radius: 6px;
}

#logincontent button {
  width: 100%;
}

.chat-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.chat-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
