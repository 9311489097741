.chat-widget {
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin: 20px auto;
}

.chat-header {
  position: sticky;
  top: 0;
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  text-align: center;
}

.chat-header h3 {
  margin: 0;
  font-size: 1.2em;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #f8f9fa;
}

.message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
  position: relative;
}

.user-message {
  background-color: #3498db;
  color: white;
  margin-left: auto;
}

.genie-message {
  background-color: #ecf0f1;
  color: #2c3e50;
  margin-right: auto;
}

.message-sender {
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
}

.message-content {
  word-wrap: break-word;
}

.message-timestamp {
  font-size: 0.7em;
  opacity: 0.7;
  margin-top: 5px;
  text-align: right;
}

.chat-input-form {
  display: flex;
  padding: 15px;
  background-color: white;
  border-top: 1px solid #e9ecef;
  border-radius: 0 0 10px 10px;
}

.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 14px;
}

.chat-input:focus {
  outline: none;
  border-color: #3498db;
}

.chat-send-button {
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-send-button:hover:not(:disabled) {
  background-color: #34495e;
}

.chat-send-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

/* Custom scrollbar */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.message-content {
  white-space: pre-line;
}

.message-content p {
  margin: 0 0 8px 0;
}

.message-content p:last-child {
  margin-bottom: 0;
}

.message-content ul {
  margin: 0;
  padding-left: 20px;
}

.message-content li {
  margin-bottom: 4px;
}

.message-content li:last-child {
  margin-bottom: 0;
}

.genie-message .message-content {
  line-height: 1.4;
}

.genie-message .message-content ul {
  list-style-type: disc;
}

.genie-message .message-content ol {
  list-style-type: decimal;
}
